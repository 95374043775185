<template>
    <div class="reason-summary-widget">
        <div class="row mb-3">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <b-button variant="light" class="mr-auto" @click="onExport">
                    Export
                </b-button>
                <b-form inline @submit.prevent>
                    <label class="mr-sm-2" for="execution-tolerance-type-filter">Type:</label>
                    <b-form-select id="execution-tolerance-type-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="type" :options="filterOptions.type" @change="requests"></b-form-select>
                    <label class="mr-sm-2" for="execution-tolerance-notation-filter">Notation:</label>
                    <b-form-select id="execution-tolerance-notation-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="notation" :options="filterOptions.notation" @change="requests"></b-form-select>
                </b-form>
            </div>
        </div>
        <b-table id="trades-table" :items="items" :fields="columns" responsive class="text-center">
            <template slot="empty">
                No results found
            </template>
            <template slot="bottom-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                    <template v-if="field.key === 'reason'">Total</template>
                    <template v-else>
                        <template v-if="notation === 'Percentage'">
                        {{sum(field.key) | number }}
                    </template>
                    <template v-else-if="notation === 'Cash'">
                        {{sum(field.key) | number | currency}}
                    </template>
                    <template v-else>
                        {{sum(field.key)}}
                    </template>
                    </template>
                </td>
            </template>
        </b-table>
    </div>
</template>

<script>
import { exportCsv } from '@/lib/Exporting';
import { map, clone } from 'lodash';

export default {
    name: 'reason-summary-widget',
    data() {
        return {
            type: 'Market',
            notation: 'Count',
            filterOptions: {
                type: [
                    {
                        value: 'Market',
                        text: 'Market'
                    },
                    {
                        value: 'Bucket',
                        text: 'Bucket'
                    },
                    {
                        value: 'Factor',
                        text: 'Factor'
                    }
                ],
                notation: [
                    {
                        value: 'Count',
                        text: 'Count'
                    },
                    {
                        value: 'Percentage',
                        text: 'Percentage'
                    },
                    {
                        value: 'Cash',
                        text: 'Cash'
                    }
                ]
            },
            hasSetupListeners: false,
            columns: [],
            items: [],
        }
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('reasonsummary', this.onReasonSummary);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('reasonsummary', this.onReasonSummary);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'ReasonSummary',
                args: {
                    filterType: this.type,
                    notationType: this.notation
                }
            });
        },
        onReasonSummary(event) {
            let items = [];
            let columns = [
                {
                    key: 'reason',
                    label: 'Reason',
                    sortable: true
                }
            ];

            let that = this;

            let keys = [];
            for(let reason in event.response) {
                let item = {
                    reason: reason,
                    total: 0
                };

                for(let key in event.response[reason]) {
                    item[key] = event.response[reason][key];
                    item.total += event.response[reason][key];

                    if(keys.indexOf(key) === -1) {
                        columns.push({
                            key: key,
                            label: key,
                            sortable: true,
                            formatter: function(value) {
                                if(that.notation === 'Percentage') {
                                    return that.$options.filters.number(value);
                                } else if(that.notation === 'Cash') {
                                    return that.$options.filters.currency(that.$options.filters.number(value));
                                } else {
                                    return value;
                                }
                            }
                        });
                    }
                }

                items.push(item);
            }

            columns.push({
                key: 'total',
                label: 'Total',
                sortable: true,
                formatter: function(value) {
                    if(that.notation === 'Percentage') {
                        return that.$options.filters.number(value);
                    } else if(that.notation === 'Cash') {
                        return that.$options.filters.currency(that.$options.filters.number(value));
                    } else {
                        return value;
                    }
                }
            })

            this.columns = columns;
            this.items = items;
        },
        onExport() {
            let headers = {};

            for(let column of this.columns) {
                headers[column.key] = column.label;
            }

            exportCsv(headers, map(this.items, clone), 'ReasonSummary');
        },
        sum(field) {
            return this.items.reduce(function(previousValue, item) {
                return previousValue + parseFloat(item[field]);
            }, 0.0);
        }
    }
}
</script>